body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(#6a0dad, #6020c7);
    padding: 10px 20px;
}

.navbar img {
    height: 40px;
}

.navbar ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    margin-left: 20px;
}

.navbar ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.navbar ul li a:hover {
    text-decoration: underline;
}

.fullscreen-background {
    background-image: url('https://via.placeholder.com/1920x1080'); /* Placeholder for background image */
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 60px; /* Height of the navbar */
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}
.welcome-message {
    font-size: 36px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px #000;
}
.search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 600px;
    width: 100%;
}
.input-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}
.input-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.input-group label {
    margin-bottom: 5px;
    font-size: 14px;
    color: white;
}
.input-group select, .input-group input[type="date"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: white;
}
.search-button {
    padding: 12px 20px;
    font-size: 18px;
    background-color: #6a0dad;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}
.search-button:hover {
    background-color: #5c0ba8;
}