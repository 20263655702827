body {
    margin: 0;
    font-family: Arial, sans-serif;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #6a0dad; /* Purple color */
    padding: 10px 20px;
}
.navbar img {
    height: 40px;
}
.navbar ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.navbar ul li {
    margin-left: 20px;
}
.navbar ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}
.navbar ul li a:hover {
    text-decoration: underline;
}
.container {
    display: flex;
    height: calc(100vh - 60px); /* Full height minus navbar */
}
.image-section {
    flex: 1;
    background-image: url('https://via.placeholder.com/800x600'); /* Placeholder image */
    background-size: cover;
    background-position: center;
}
.form-section {
    flex: 1;
    padding: 20px;
    overflow-y: auto; /* Make this section scrollable */
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
h1 {
    margin: 0;
    font-size: 24px;
    color: #6a0dad; /* Match navbar color */
}
.input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}
.input-group label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}
.input-group input, .input-group select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.book-button {
    padding: 12px 20px;
    font-size: 18px;
    background-color: #6a0dad;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}
.book-button:hover {
    background-color: #5c0ba8;
}