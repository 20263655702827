.book {
  background: linear-gradient(to right, #fff8be 0%, #ffffe9 100%);

        background-repeat: no-repeat;
        height: 100vh;
        width: 100vw;
        margin-top: -20px;
}
.join__inpBox {
    width: 250px !important;
    height: 26px !important;
}
