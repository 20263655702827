.verticalLine {
    border-right: 3px solid #000000;
}

#getResult {
    padding: 5px;
    background-color: rgb(148, 1, 136);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
.myTable tr:nth-child(odd) th {
    background-color: #6782fc !important;
}
/* .myTable tr:nth-child(odd) td {
    background-color: red !important;
} */
#myTable2 tr:nth-child(even) td {
    background-color: #48ff00 !important;
}

td, th {
    border: 1px solid #0c0c0c;
    text-align: left;
    padding: 8px;
}
