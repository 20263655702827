/* General styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f3f4f6;
    color: #333;
  }
  
  .construction-container {
    text-align: center;
    padding: 50px;
    background: linear-gradient(to bottom, #e8f0fe, #f3f4f6);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .construction-header .logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .construction-header h1 {
    font-size: 24px;
    font-weight: bold;
    color: #2a69ac;
    margin: 0;
  }
  
  .construction-content .animated-icon {
    font-size: 50px;
    color: #ffa500;
    margin: 20px;
    animation: spin 2s linear infinite;
  }
  
  .blinking-text {
    font-size: 28px;
    color: #e53e3e;
    animation: blink 1.5s infinite;
  }
  
  .construction-content p {
    font-size: 18px;
    margin: 20px 0;
    color: #555;
  }
  
  /* Animations */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  