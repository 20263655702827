.booking {
  background: linear-gradient(to right, #f5dd00 0%, #fffea7 100%);
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.flex-col{
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .sel__img {
    height: 17%;
    width: 80%;
    left: 35px;
  }
}

@media screen and (min-width: 768px) {
  .sel__img {
    height: 50%;
    width: 50%;
    left: 25%;
  }
}

#combo-box-demo {
  align-items: left !important;
  text-align: left !important;
}

@-webkit-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  92% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


#sel__cf4a .sel__img {
  position: absolute;
  display: block;
  box-shadow: 0 7px 13px rgb(0 0 0 / 0.8);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: 5px !important;

}

#sel__cf4a .sel__img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 8s;

  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 8s;

  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 8s;

  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  /* Total time for one loop of 4 photo */
}

#sel__cf4a .sel__img:nth-of-type(1) {
  -webkit-animation-delay: 12s;
  /* 4th photo start displey from 12 second */
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  animation-delay: 12s;
}

#sel__cf4a .sel__img:nth-of-type(2) {
  -webkit-animation-delay: 8s;
  /* 3th photo start displey from 8 second */
  -moz-animation-delay: 8s;
  -o-animation-delay: 8s;
  animation-delay: 8s;
}

#sel__cf4a .sel__img:nth-of-type(3) {
  -webkit-animation-delay: 4s;
  /* 2nd photo start displey from 4 second */
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}

#sel__cf4a .sel__img:nth-of-type(4) {
  -webkit-animation-delay: 0;
  /* 1st photo start displey from 12 second */
  -moz-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
}